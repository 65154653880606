import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import classnames from 'classnames';

import StyledLabelWrapper from '../styled-label/styled-label';
import ComposedLabelValue from './composed-label-value';
import { mapWithKey } from '../../components-v2/layout/utils';
import { GENERIC_KEY_PREFIXES } from '../../components-v2/layout/constants';

const namespace = 'ui-search-composed-label';

const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;
const DESTAQUE_COMPATIBLE_ICON = 'destaque-compatible-icon';
const Element = 'div';

const ComposedLabel = ({ className, text, values, ...props }) => {
  const componentsToRender = [];
  const icon = [];
  const UI_SEARCH = 'ui-search';

  let currentIndex = 0;
  let match = PLACEHOLDER_FORMAT_REGEX.exec(text);

  while (match !== null) {
    const matchIndex = match.index;
    const placeHolder = match[0];
    const valueKey = placeHolder.split(PLACEHOLDER_DELIMITERS_REGEX)[1];
    const normalTextToRender = matchIndex > 0 && text.substring(currentIndex, matchIndex);

    currentIndex = matchIndex + placeHolder.length;

    if (normalTextToRender) {
      componentsToRender.push(
        <StyledLabelWrapper className={`${namespace}__normal-text`} text={normalTextToRender} {...props} />,
      );
    }

    const valueProps = values?.find((value) => value.key === valueKey);

    if (valueProps) {
      if (valueProps.id === DESTAQUE_COMPATIBLE_ICON) {
        icon.push(<ComposedLabelValue className={`${namespace}__icon`} {...valueProps} />);
      } else {
        componentsToRender.push(<ComposedLabelValue className={`${namespace}__value`} {...valueProps} />);
      }
    }

    match = PLACEHOLDER_FORMAT_REGEX.exec(text);
  }

  if (currentIndex < text.length) {
    componentsToRender.push(
      <StyledLabelWrapper className={`${namespace}__normal-text`} text={text.substring(currentIndex)} {...props} />,
    );
  }

  return (
    <>
      {icon?.[0] && <Element className={`${UI_SEARCH}-destaque__compatible__icon`}>{icon[0]}</Element>}
      <StyledLabelWrapper
        className={classnames(namespace, className)}
        text={currentIndex > 0 ? mapWithKey(componentsToRender, GENERIC_KEY_PREFIXES.MODAL_COMPONENTS) : text}
        {...props}
      />
    </>
  );
};

ComposedLabel.propTypes = {
  className: string,
  text: string,
  values: arrayOf(
    shape({
      key: string,
      type: string,
      text: string,
      font_weight: string,
      color: string,
    }),
  ),
};

ComposedLabel.defaultProps = {
  className: null,
  text: '',
  values: [],
};

export default ComposedLabel;
