import React, { memo } from 'react';
import { string } from 'prop-types';

import { useSearch } from '../../hooks/context';
import FacetedModalMobile from './faceted-modal.mobile';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * FacetedSearchMobileContainer
 * @description This component is responsible for rendering the faceted search modal on mobile.
 * @param {string} [props.url=''] - The URL to redirect to when the action button is clicked.
 * @param {string} [props.icon=''] - The icon to display in the action button.
 * @returns {JSX.Element|null} The rendered component or null if faceted_config is not available.
 */
const FacetedSearchMobileContainer = ({ url = '', icon = '' }) => {
  const { new_faceted_search: newFacetedSearch } = useSearch();

  if (!newFacetedSearch?.faceted_config) {
    return null;
  }

  return (
    <FacetedModalMobile
      actionLabel={newFacetedSearch.labels.action}
      modalTitle={newFacetedSearch.labels.title.search}
      IconComponent={icon}
      url={url}
    />
  );
};

FacetedSearchMobileContainer.propTypes = {
  icon: string,
  url: string,
};

const MemoFacetedSearchMobileContainer = memo(FacetedSearchMobileContainer);

export default withComponentHandler(MemoFacetedSearchMobileContainer, { componentName: 'Faceted-mobile' });
