import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classnames from 'classnames';
import { ListItem } from '@andes/list';

import AnimatedSwitchV2 from '../../animated-switch/animated-switch-v2';
import Highlight from '../../card/highlight/highlight-label';
import IconLabel from '../../../components-v2/icons/utils/icon-label'; // TODO: check if we can be removed this component
import useFilterSearch from '../../../hooks/use-filter-search';
import { useSearch } from '../../../hooks/context';
import { mapWithKey } from '../../../components-v2/layout/utils';

const namespace = 'ui-search-filter';

const getFilterDescription = ({ title, subtitle, highlight, name, filterId }) => {
  const titleClassname = classnames(`${namespace}__title`, {
    [`${namespace}__meli-plus`]: title?.icons && title?.icons[0] === 'meli_plus_icon',
  });

  if (title && highlight) {
    const componentsToRender = [];

    componentsToRender.push(
      <>
        <IconLabel className={titleClassname} {...title} htmlId={`${namespace}-${filterId}-title`} Tag="span" />
        {subtitle && (
          <IconLabel
            className={`${namespace}__subtitle`}
            htmlId={`${namespace}-${filterId}-subtitle`}
            Tag="span"
            {...subtitle}
          />
        )}
      </>,
    );
    componentsToRender.push(<Highlight {...highlight} />);

    return mapWithKey(componentsToRender, `${namespace}-${filterId}`);
  }

  if (title) {
    return (
      <>
        <IconLabel className={titleClassname} {...title} htmlId={`${namespace}-${filterId}-title`} Tag="span" />
        {subtitle && (
          <IconLabel
            className={`${namespace}__subtitle`}
            htmlId={`${namespace}-${filterId}-subtitle`}
            Tag="span"
            {...subtitle}
          />
        )}
      </>
    );
  }

  if (highlight) {
    return <Highlight {...highlight} />;
  }

  return name;
};

const getInputProps = ({ title, subtitle, filterId }) => ({
  'aria-labelledby': classnames({
    [`${namespace}-${filterId}-title`]: !!title,
    [`${namespace}-${filterId}-subtitle`]: !!subtitle,
  }),
});

const HighlightedFilter = ({ expanded_values, id: filterId }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const { url, switch: _switch } = expanded_values[0];
  const doFilterSearch = useFilterSearch(url, filterId);

  const handleClick = () => {
    if (doFilterSearch) {
      doFilterSearch();

      return;
    }

    window.location.href = url;
  };

  if (expanded_values.length < 1) {
    return null;
  }

  const { title, subtitle, highlight, name } = expanded_values[0];

  const filterDescription = getFilterDescription({ title, subtitle, highlight, name, filterId });
  const inputProps = getInputProps({ title, subtitle, filterId });

  return (
    <ListItem
      className={classnames(
        namespace,
        `${namespace}--highlighted`,
        `${namespace}--${filterId}`,
        { 'filter-mobile-pointer-event': !_switch },
        {
          'shops__filter-highlighted-list': isShops,
        },
      )}
      data-testid={filterId}
      title={filterDescription}
      rightContent={
        _switch ? <AnimatedSwitchV2 onClick={handleClick} checked={_switch.is_on} inputProps={inputProps} /> : null
      }
    />
  );
};

HighlightedFilter.propTypes = {
  expanded_values: arrayOf(
    shape({
      id: string,
      url: string,
      name: string,
      switch: shape(),
      title: shape(),
      subtitle: shape(),
      highlight: shape(),
    }),
  ).isRequired,
  id: string.isRequired,
};

export default HighlightedFilter;
