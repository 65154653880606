import React, { memo, useMemo } from 'react';
import { element, string } from 'prop-types';

import classnames from 'classnames';
import FacetedSearchMobile from 'vis-faceted-search/lib/Mobile';

import Modal from '../../components/modal/base.mobile';
import IconFilterToolbar from '../icons/sources/toolbar-filter';
import ButtonFaced from './components/button-faced';
import useFacetedMobile from './hooks/hook.mobile';
import { withComponentHandler } from '../../hocs/with-component-handler';

const namespace = 'ui-search';

/**
 * FacetedModalMobile component renders a modal with faceted search functionality for mobile devices.
 *
 * @param {string} props.actionLabel - The label for the action button.
 * @param {string} props.modalTitle - The title of the modal.
 * @param {React.Element} [props.IconComponent] - Optional custom icon component for the modal.
 * @param {string} [props.url] - Optional URL for the modal.
 * @returns {JSX.Element} The rendered FacetedModalMobile component.
 */
const FacetedModalMobile = ({ actionLabel, modalTitle, IconComponent, url = '' }) => {
  const { hideModal, isOpen, openModal, handlerAction, renderConfig, initialState, onChange } = useFacetedMobile();

  const actions = useMemo(
    () => ({
      fixed: true,
      primary: <ButtonFaced actionLabel={actionLabel} handlerAction={handlerAction} fullWidth />,
    }),
    [actionLabel, handlerAction],
  );

  return (
    <Modal
      modalClassName={classnames(`${namespace}-faceted-search--modal`, 'pi', `${namespace}-modal--real-estate`)}
      icon={IconComponent ?? <IconFilterToolbar />}
      label={actionLabel}
      modalTitle={modalTitle}
      modalUrl="#faceted-search"
      hideModal={hideModal}
      openModal={openModal}
      open={isOpen}
      url={url}
      withLink
      data-testid="faceted-modal-mobile"
      actions={actions}
    >
      <FacetedSearchMobile renderConfig={renderConfig} initialState={initialState} onChange={onChange} />
    </Modal>
  );
};

FacetedModalMobile.propTypes = {
  actionLabel: string.isRequired,
  IconComponent: element,
  modalTitle: string.isRequired,
  url: string,
};

const MemoFacetedModalMobile = memo(FacetedModalMobile);

export default withComponentHandler(MemoFacetedModalMobile, { componentName: 'Faceted-modal' });
